import React from "react";
import AdminLayout from "../../LayoutWrapper/AdminLayout";
import { Col, Container, Row } from "react-bootstrap";
import "../../CSS/Safety/index.css";
import Vaccinated from "../../Images/Safety/syringe.jpg";
import Mask from "../../Images/Safety/face-mask.jpg";
import { useTranslation } from "react-i18next";
import Scanning from "../../Images/Safety/scanning.jpg";

const Safety = () => {
  const { t } = useTranslation();
  return (
    <AdminLayout>
      <section className="safetyBanner py-5">
        <div className="container-fluid">
          <Row>
            <Col lg={7}>
              <div className="content-Wrapper ps-lg-5">
                <h1 className="pageTitle mb-4">{t("page_title_safety")}</h1>
                <p className="paragraph">{t("safety_banner_para")}</p>
              </div>
            </Col>
            <Col lg={5}>
              <div className="safetyImg bgImg h-100"></div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="helping-each-other px-5 pb-5">
        <div className="container-fluid">
          <Row>
            <h2 className="section-title text-center mb-5">
              {t("safety_section_title1")}
            </h2>
            <Col lg={4}>
              <div className="imgBox-wrapper">
                <div className="img-wrapper text-center">
                  <img src={Vaccinated} alt="" />
                </div>
                <div className="imgBox-content-wrapper">
                  <h4 className="imgBox-title text-center my-3">
                    {t("covid_point1")}
                  </h4>
                 
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="imgBox-wrapper">
                <div className="img-wrapper text-center">
                  <img src={Scanning} alt="" />
                </div>
                <div className="imgBox-content-wrapper">
                  <h4 className="imgBox-title text-center my-3">
                    {t("covid_point2")}
                  </h4>
                 
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="imgBox-wrapper">
                <div className="img-wrapper text-center">
                  <img src={Mask} alt="" />
                </div>
                <div className="imgBox-content-wrapper">
                  <h4 className="imgBox-title text-center my-3">
                    {t("covid_point3")}
                  </h4>
                 
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="safety-points py-5 ">
        <Container>
          <Row>
            <Col>
              <p className="paragraph text-white">
                {t("safety_section_para2")}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </AdminLayout>
  );
};

export default Safety;
