import React from "react";
import AdminLayout from "../../LayoutWrapper/AdminLayout";
import "../../CSS/Homepage/index.css";
import GooglePlay from "../../Images/Homepage/google-play.png";
import AppStore from "../../Images/Homepage/app-store.png";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Homepage = () => {
  const { t } = useTranslation();
  return (
    <AdminLayout>
      <section className="homeBanner py-lg-4 py-5">
        <div className="container">
          <div className="row flex-lg-row flex-column-reverse">
            <div className="col-lg-5">
              <div className="leftContent-Wrapper py-5 text-md-start text-center">
                <h1 className="pageTitle">
                  {/* Exclusive Personal
                  <br /> Driver Platform */}
                  {t("page_title_home")}
                </h1>
                <div className="signupBtns-warpper pb-3">
                  {/* <button className="bgBtn me-4">
                    {t("home_banner_btn1")}
                  </button>
                  <button className="bgNoneBtn">{t("home_banner_btn2")}</button> */}
                </div>
                <p className="paragraph">{t("home_dow_app_text")}</p>
                <div className="Online-Store-wrapper">
                  <img src={AppStore} alt="" className="Online_store me-4" />
                  <img src={GooglePlay} alt="" className="Google_Play" />
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="homeBanner-img-wrapper w-100 h-100 bgImg">
                {/* <img src={HomeBanner} alt="" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="investEarn py-5 bgImg">
        <div className="container py-5">
          <div className="row">
            <div className="col">
              <h2 className="section-title text-center text-white">
                {t("Invest_and_Earn")}
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section className="estimate py-5">
        <Container>
          <Row>
            <div className="col-lg-4">
              <h2 className="section-title text-white text-md-start text-center">
                {t("Get_an_estimate")}
              </h2>
            </div>
            <div className="col-lg-8">
              <div className="estimate-form-wrapper py-lg-0 py-4 text-md-start text-center">
                <input
                  type="text"
                  className="pickup me-2"
                  placeholder={t("Pick_up_Point")}
                />
                <input
                  type="text"
                  className="drop me-2 my-md-0 my-4"
                  placeholder={t("Drop_Point")}
                />
                <button className="getBtn my-lg-0 my-4">
                  {t("Get_Estimate")}
                </button>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <section className="applyDrive py-lg-4 py-5 px-lg-0 px-2">
        <div className="container-fluid">
          <Row>
            <Col lg={5}>
              <div className="applyDrive-img-wrapper w-100 h-100 bgImg"></div>
            </Col>
            <Col lg={7}>
              <div className="content-wrapper mt-lg-0 mt-5">
                <h4>{t("applyDrive_title1")}</h4>
                <h3 className="mt-3">{t("applyDrive_title2")}</h3>
                <p className="mt-4 paragraph">
                  <strong>{t("applyDrive_subtitle1")}</strong>
                  <br />
                  {t("applyDrive_para1")}
                </p>
                <p className="mt-4 paragraph">
                  <strong>{t("applyDrive_subtitle2")}</strong>
                  <br />
                  {t("applyDrive_para2")}
                </p>
                {/* <p className="mt-4 paragraph">
                  <strong>Get paid instantly:</strong> <br /> Cash out your
                  earnings whenever you want.
                </p> */}
              </div>
            </Col>
            {/* <div className="applyDrive-Btn-wrapper text-center mt-5 mb-3 ps-lg-5">
              <button className="applyDrive-Btn ms-lg-5 mybtns">
                {t("applyDrive_btn")}
              </button>
            </div> */}
          </Row>
        </div>
      </section>
      <section className="home-aboutUs pt-4 pb-5">
        <Container>
          <Row>
            <Col>
              <h2 className="section-title text-black text-center mb-4">
                {t("homepage_about_title")}
              </h2>
              <p className="paragraph pb-5">
                {t("homepage_about_para1")}
                <br />
                <br />
                {t("homepage_about_para2")}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </AdminLayout>
  );
};

export default Homepage;
