import React from "react";
import AdminLayout from "../../LayoutWrapper/AdminLayout";
import { Col, Container, Row } from "react-bootstrap";
import "../../CSS/About/index.css";
import { useTranslation } from "react-i18next";
import profile from "../../Images/About/profile.jpg";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <AdminLayout>
      <section className="aboutBanner py-100 bgImg">
        <Container>
          <Row>
            <Col>
              <h1 className="pageTitle text-center text-white">
                {t("page_title_about")}
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bannerBelowContent pt-5 pb-5">
        <Container>
          <Row>
            <Col>
              <h2 className="section-title text-center mb-4">
                {t("about_section_title1")}
              </h2>
              <p className="paragraph">{t("about_section_para1")}</p>
            </Col>
           
          </Row>
          <Row>
            <Col>
              <h4 className="section-title-about text-center mb-4">
              Your Gateway to Relaxation and Rejuvenation at Comfort Trip
              </h4>
              <p className="paragraph">Welcome to Comfort Trip, your one-stop shop for crafting unforgettable getaways designed for pure relaxation and rejuvenation. We're a passionate team of travel experts dedicated to curating bespoke experiences that cater to your specific needs and desires. Whether you crave a secluded beach escape, a charming village exploration, or an invigorating adventure, we'll help you unwind and reconnect with yourself amidst breathtaking landscapes and unique cultures.</p>
              <h4 className="section-heading-about text-start mb-4">
              Our Story: A Passion for Crafted Comfort
              </h4>
              <p className="paragraph">Comfort Trip wasn't born in a corporate boardroom. It emerged from a shared desire – a yearning for travel experiences that transcended the typical tourist itinerary. We, the founders, were seasoned travelers ourselves, but we often felt a disconnect between the advertised packages and the actual experience. We craved personalized journeys that fostered genuine relaxation and allowed us to truly immerse ourselves in the beauty of a destination. This gap in the travel industry sparked the creation of Comfort Trip.</p>
              <h4 className="section-heading-about text-start mb-4">
              Our Mission: Curating Personalized Journeys for Your Well-being
              </h4>
              <p className="paragraph">We believe travel should be a source of well-being, a chance to escape the daily grind and return feeling refreshed and revitalized.  That's why we go beyond just booking flights and hotels. We take the time to understand you, your travel preferences, and your deepest relaxation goals. It's through this personalized approach that we craft journeys that cater to your individual needs and desires.</p>
              <h4 className="section-heading-about text-start mb-4">
              Our Core Values: Guiding Principles for Your Comfort
              </h4>
              <p className="paragraph">At Comfort Trip, four core values guide every decision we make and every experience we curate:</p>
              <p className="paragraph"><span style={{fontWeight:"600"}} >Customization:*</span> We believe a one-size-fits-all approach doesn't cut it. We work closely with you to understand your travel personality, interests, and relaxation needs. This personalized approach ensures every aspect of your trip, from accommodations to activities, aligns perfectly with your vision.</p>
              <p className="paragraph"><span style={{fontWeight:"600"}} >Authenticity:*</span>  We take you beyond the tourist traps and immerse you in the true essence of a destination. You'll experience local culture, cuisine, and traditions, fostering a deeper connection with the place you visit.</p>
              <p className="paragraph"><span style={{fontWeight:"600"}} >Well-being:*</span>  We prioritize your well-being throughout the journey. Whether it's finding the perfect spa retreat, incorporating mindfulness practices, or tailoring a physical activity schedule that suits your fitness level, we ensure your trip fosters holistic relaxation and rejuvenation.</p>
              <p className="paragraph"><span style={{fontWeight:"600"}} >Sustainability:*</span>  We understand the importance of responsible tourism. We partner with eco-conscious hotels, activities that respect the environment, and local communities that benefit from your visit.</p>
              <h4 className="section-heading-about text-start mb-4">
              Our Team: Your Travel Experts and Comfort Champions
              </h4>
              <p className="paragraph">Our team is at the heart of Comfort Trip. We're a diverse group of passionate travel enthusiasts, each with a wealth of experience and a deep understanding of different cultures and destinations. We're not just travel agents; we're your dedicated advisors, always on hand to answer your questions, address your concerns, and ensure your comfort throughout the journey.</p>
              <h4 className="section-heading-about text-start mb-4">
              Our Commitment to You: Unforgettable Memories and Lasting Relaxation
              </h4>
              <p className="paragraph">At Comfort Trip, we believe in the transformative power of travel. Our commitment is simple: to craft unforgettable getaways that allow you to disconnect from your daily life, reconnect with yourself, and return home feeling revitalized and ready to face the world. We're more than just a travel company; we're your partners in creating lasting memories and fostering your well-being through the joy of travel.</p>
            
              <h4 className="section-heading-about text-start mb-4">
              Ready to Start Your Comfort Journey?
              </h4>
              <p className="paragraph">We invite you to reach out to our friendly team today. Let's discuss your ideal escape, your relaxation goals, and the destinations that call to your heart. We'll listen attentively, share our expertise, and curate a personalized itinerary that promises unforgettable comfort and rejuvenation.</p>
              <p className="paragraph">Together, let's turn your travel dreams into a reality at Comfort Trip.</p>
            </Col>
           
          </Row>
        </Container>
      </section>
     
      
    </AdminLayout>
  );
};

export default AboutUs;
